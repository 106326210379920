.vision {
  margin:6.5vh 5vh 4vh 5vh;
  background-color:rgba(255, 255, 255, 0.8);
  height: max-content;
  display: grid;
  grid-template-rows: 15% auto;
  }
.vision_heading {
  color: #000;
  text-align: center;
  font-family: Odor Mean Chey;
  font-size: 9vh;
  font-weight: 400;
  padding:2vh 2vh;
  }
.vision_body{
  display: grid;
  grid-template-columns: 40% auto;
  align-items: center;
  

  }
.vision .content {
  margin-right: 10vh;
  padding:3vh 2vh 5vh 2vh;
  color: #000;
  font-family: Inter;
  font-size: 3.4vh;
  opacity: 0;
  text-align:justify;

}

.vision .vision_image{
  transform: scale(0);
  transform-origin: center; 
  width: 60%;
}
@media(max-width:980px){
  .vision{
    grid-template-rows: auto auto;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .vision_body{
    display: grid;
    grid-template-columns:100% ;
    grid-template-rows: auto auto;
   
  }
 
  .vision .content{
    font-size: 2.6vw;
    margin:0 5vw ;
   
  } 
  .vision_heading{
    font-size: 6vw;
  }
  .vision_image{
    height: 10%;
  }
}
@media(max-width:770px){
  .vision .heading{
    font-size: 10vw;
  }
  .vision .content{
    font-size: 3vw;
  }
  .vision_body{
    grid-template-columns: 0% 100%;
  }
  .vision_image{
    height:0%;
  }
}