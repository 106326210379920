.hero {
    background-color: white;
    background: url('../Assets/hero-bg.jpg') no-repeat;
    background-position: center;
    height: 100vh;
    margin:0px;
    overflow: hidden;
    background-size: 100% 100%;
    display:grid;
    grid-template-columns: 55% 45%;
    gap: 2vh; 
}

.hero .content {
    display:grid;
    gap: 2vh; 
    grid-template-rows:10% auto auto 10%;
}

.hero .title {
    color: black;
    text-shadow: 7px 7px 5px rgba(0, 0, 0, 0.50);
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 15vh;
    font-weight: 500;
    text-align: center;
    transform: translateY(10vh);
    animation: title 0.5s forwards ease-in;
    animation-delay: 2.5s;
}

.hero .text{
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 8vh;
    font-weight: 100;
    text-align: center;
    transform: translateY(400%);
    animation: text 1s forwards ease-in;
    animation-delay: 2s;
}

.hero .side_img{
    width: 120vh;
    height: 100vh;
    mix-blend-mode: color-burn;
    transform: translateX(100%);
    animation: slideInImage 2s ease-in forwards;
    animation-delay: 1.2s;
}

@keyframes title {
    100% {
        transform:scale(0.8) translateX(-5%) translateY(0%);
    }
}

@keyframes text {
    100% {
        transform:scale(0.6) translateX(-10%) translateY(0%);
    }
}

@keyframes slideInImage {
    100% {
        transform: translateX(-20%);
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    .hero .title {
        font-size: 9vw;
    }
    .hero .text {
        font-size: 5vw;
    }
}

@media (max-width: 768px) {
    .hero {
        grid-template-columns: 100% 0%;
    }
    .hero .side_img {
        display: none;
    }
    .hero .title {
        font-size: 12vw;
    }
    .hero .text {
        font-size: 6vw;
    }
    @keyframes title {
        100% {
            transform:scale(0.9) translateX(0%) translateY(10vh);
            overflow-x: auto;
        }
    }
    @keyframes text {
        100% {
            transform:scale(0.7) translateX(0%) translateY(-10vh);
            overflow-x: auto;
        }
    }
}