.footer {
  position: relative;
 margin-top:4vh;
  min-height: 100px;
  padding: 0px 8vh;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}
.footer ul{
  justify-content: center;
}
.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
  justify-content: space-evenly;
}

.social-icon__link {
  font-size: 4vh;
  color: #080808;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-15px);
}

.menu__link {
  font-size: 2.8vh;
  color: #0f0f0f;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #0c0c0c;
  margin: 1vh 0%;
  font-size: 2vh;
  font-weight: 300;
}
@media(max-width:770px){
  .footer{
    margin:6vw 3vw;
    font-size: 3vw;
    padding: 3vw 5vw;

  }
  .menu__link {
    font-size: 5vw;
    color: #000000;
  }
  .social-icon__link{
    font-size: 5vw;
  }
  
}