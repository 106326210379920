.description {
    background-color: white;
    padding:5vh 12vh;
    color: #010101;
    text-align: center;
    font-family: Inter;
    font-size: 4vh;
    font-weight: 600;
  
  }
  @media(max-width:770px){
    .description{
      font-size: 3vw;
      padding: 4vw 6vw;
    }
  }