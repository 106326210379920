.contact {
    font-family: Arial, sans-serif;
    background-color: #f0f0f000;
    margin: 0;
    padding: 0;
    /* width: 100%; */
    height: max-content;
    border-radius: 5px;
    backdrop-filter: blur(6px);
    margin: 30px 30px;
    border: 1px black solid;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.contact_heading {
    /* background-color: #33333300; */
    color: #000000;
    text-align: center;
    padding: 20px 0;
    font-size: 3vh ;
    margin:20px 0px;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.contact h1 {
    margin: 0;
}

.contact main {
    padding-top: 40px;
    padding: 20px;
}

.col-25 {
    float: left;
    width: 20%;
    margin-top: 15px;
  }
  
  .col-75 {
    float: left;
    width: 70%;
    margin-top: 16px;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
.contact label {
    padding: 4px 12px 6px 0;
    display: inline-block;
    font-size: 3vh;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    background-color: transparent;
    width: 100%;
    font-size: 2.2vh;
    border: none;
    border-bottom: 2px solid #000000;
    resize: vertical;
    outline: none;
    color: #050505;
}

.row button {
    background-color: #060606;
    color: white;
    margin:20px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: center;
    font-size: 3vh;
  }
  .row button:hover {
    background-color: #605d5d;
    border: 2px #060606 solid;
  }
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
    .contact label{
      margin: 4vw 1vw;
      overflow-x: hidden;
    }
    .contact_heading{
      font-size: 2.6vw;
      padding: 1vw;
    }
    .row button{
      font-size: 4vw;
      padding: 12px 12px;
    }
  }
  @media(max-width:480px){
    .contact label{
      font-size: 6VW;
    }
    .row button{
      font-size: 4vw;
      padding: 8px 8px;
    }
    .contact_heading{
      font-size: 3.2vw;
      padding: 1vw;
     
    }
  }