#myLinks{
  display: none;
}
.navbar {
  display: grid;
  position: fixed;
  grid-template-columns: 10vw 90vw ;
  height: 10vh;
  background-color: black;
  top: -12vh;
  transition: 0.3s ease-in-out;
  z-index: 2;
  align-items: center;
}
.dropdown{
  display: none;
}
.myLinks {
  padding-right:3vh;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  gap:0.6vh;
  font-size: 3.4vh;
}
  
.myLinks li {
  list-style: none;
  margin-right: 4vh;
}

.navbar a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 25px;
  overflow: hidden;
}


.navbar a:hover{
  border-bottom: 2px white solid;
  transition: 0.1s ease-in;
}

.navbar img {
  height: 7vh; 
  
}
@media(max-width:770px){
  .navbar {
  align-items: start;
    background-color: #333;
   grid-template-columns: 4fr 1fr; 
   grid-template-rows: 1fr 1fr;
   justify-items: left;
   width: 100%;
  }
  .myLinks{
    display: none;
  }
  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  .dropdown {
    float: left;
   
    display: block;
  }
  
  .dropdown #dropbtn {
    position: relative;
    display: block;
    font-size: 4vh;  
    border: none;
    outline: none;
    color: white;
    padding: 3vh 2vh;
    background-color: inherit;
    font-family: inherit;
    
  }
 
  .dropdown-content {
    background-color: #f9f9f9;
    display: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .logo{
    margin:15px 18px;
  
  }
 
}